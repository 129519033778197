<template>
	<div>
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="邀请报名" name="1"></el-tab-pane>
			<el-tab-pane label="审核进度" name="2"></el-tab-pane>
			<el-tab-pane label="样品进度" name="3"></el-tab-pane>
			<el-tab-pane label="信息变更" name="4"></el-tab-pane>
		</el-tabs>
		<e-table :tableCols="notificationTableConf" :dataOrigin="dataOrigin">
			<template #2>
				<el-checkbox v-model="checked">短信通知</el-checkbox>
			</template>
		</e-table>
	</div>
</template>

<script>
import {notificationTableConf} from '@data/system/notification'
export default {
	name: 'nNotification',
	data() {
		return {
			activeName: '1',
			notificationTableConf,
			dataOrigin: {
				data: [
					{
						1: '邀请报名'
					}
				]
			}
		}
	},
	methods: {
		handleClick() {
		}
	}
}
</script>